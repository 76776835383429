<template>
  <section>
    <div class="content-header">
      <h2>Positive Space Program</h2>
    </div>
    <div class="content-wrapper">
      <p>On campuses, the Positive Space Program is intended to create and identify respectful, supportive and safe learning and working environments for Two Spirit, lesbian, gay, bisexual, transgender, queer, questioning, intersex and asexual (2SLGBTQQIA+) students, employees and community members, as well as for their allies and people researching 2SLGBTQQIA+ issues.</p>
      <p>Deirdre Pike, a 2SLGBTQQIA+ advocate and social planner, says that Positive Spaces are designed to help create a learning environment that:</p>
      <ul>
        <li class="pb-2">Is free of discrimination on the basis of sexual orientation and gender identity</li>
        <li class="pb-2">Allows all students to feel included and welcomed so they may focus their energies on academic objectives as well as partake in school social life and co-curricular activities</li>
        <li class="pb-2">Allows all members of the school community to feel welcomed and included in order to create positive learning and workplace environments</li>
        <li class="pb-2">Decreases fear and disapproval of sexual and gender diversity</li>
        <li>Encourages inclusion of 2SLGBTQQIA+ peoples’ lives, politics, cultures, families and histories in curricula, course offerings and research opportunities</li>
      </ul>
      <p>Many principles from the positive space concept can be used in thinking about you can build an inclusive classroom community that promotes belonging and wellbeing for all of your students. If you’d like to see an example of what Positive Space Training looks like, check out this resource from Trent University, <a :href="`${publicPath}docs/community/ResLife Positive Space Online Workshop July 2021.pptx`" download>Positive Space Training for Residence Life Dons</a>. Used with permission from Krista Kermer and Faith Mwesigye.</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
